<template>
  <div class="homeBanner">
    <div class="homeWrapdiv1 main clearfix">
      <div class="homeWrapdiv1fl fl">
        <div
          class="clearfix homeWrapdiv1fllist"
          v-for="(item, index) in facultyList"
          :key="index"
        >
          <div class="homeWrapdiv1fllistd">
            <strong @click="goseacher(item, '')">{{ item.name }}</strong>
            <span
              v-for="(ite, ind) in item.child"
              :key="ind"
              @click="goseacher(item, ite)"
              >{{ ite.name }}</span
            >
            <i class="iconfont icon-home_sj"></i>
          </div>
          <dl class="homeWrapdiv1fllistdl">
            <dt @click="goseacher(item, '')">{{ item.name }}</dt>
            <dd class="clearfix">
              <span
                v-for="(ite, ind) in item.child"
                :key="ind"
                @click="goseacher(item, ite)"
                >{{ ite.name }}</span
              >
            </dd>
          </dl>
        </div>
        <div class="clearfix homeWrapdiv1fllist" v-if="more.length > 0">
          <div class="homeWrapdiv1fllistd">
            <strong>其他学科 </strong>
            <i class="iconfont icon-home_sj"></i>
          </div>
          <dl class="homeWrapdiv1fllistdl">
            <template v-for="item in more">
              <dt @click="goseacher(item, '')">{{ item.name }}</dt>
              <dd class="clearfix">
                <span
                  v-for="(ite, inde) in item.child"
                  :key="inde"
                  @click="goseacher(item, ite)"
                  >{{ ite.name }}</span
                >
              </dd>
            </template>
          </dl>
        </div>
      </div>
      <div class="homeWrapdiv1fl1 fl">
        <el-carousel trigger="click" height="348px" width="620px">
          <el-carousel-item v-for="(item, index) in banner" :key="index">
            <img
              :src="item.image"
              :alt="item.title"
              class="homeWrapdiv1fl1img"
              @click="learns(item)"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="homeWrapdiv1fr fr">
        <div class="homeWrapdiv1frdiv1">
          <dl class="homeWrapdiv1frdiv1dl clearfix">
            <dt>
              <img
                :src="
                  infos.head_img
                    ? infos.head_img
                    : require('@/assets/images/head_default.png')
                "
                alt=""
                class="homeWrapdiv1fl1img"
              />
            </dt>
            <dd>
              <h6>{{ infos.name }}</h6>
              <el-tooltip class="item" effect="light" placement="bottom">
                <div slot="content">
                  {{ infos.faculty_name }}
                  <span v-if="infos.enter_year"> ·{{ infos.enter_year }}</span>
                  <span v-if="infos.enter_year">级·</span>{{ infos.major_name }}
                </div>
                <p>
                  {{ infos.faculty_name }}
                  <span v-if="infos.enter_year"> ·{{ infos.enter_year }}</span>
                  <span v-if="infos.enter_year">级·</span>{{ infos.major_name }}
                </p>
              </el-tooltip>
            </dd>
          </dl>
          <ul class="homeWrapdiv1frdiv1oul">
            <li>
              <h6>{{ users.task_count }}</h6>
              <p>待完成任务</p>
            </li>
            <li>
              <h6>{{ users.study_day }}</h6>
              <p>累计学习</p>
            </li>
            <li>
              <h6>{{ users.continuous_learning_days }}</h6>
              <p>连续学习</p>
            </li>
          </ul>
        </div>
        <dl class="homeWrapdiv1frdiv2dl" v-if="noticeLists.length > 0">
          <dt>公告提醒</dt>
          <dd>
            <div
              class="homeWrapdiv1frdiv2dld clearfix"
              v-for="(item, index) in noticeLists"
              :key="index"
              @click="notices(item.id)"
            >
              <span>TOP</span>
              <h6>{{ item.notice_name }}</h6>
              <p>
                {{ item.make_time | formatimes("yyyy-MM-dd") }}
              </p>
            </div>
          </dd>
        </dl>
        <dl class="homeWrapdiv1frdiv2dl" v-if="studyList.length > 0">
          <dt>任务提醒</dt>
          <dd>
            <div
              class="homeWrapdiv1frdiv2dld clearfix"
              v-for="(item, index) in studyList"
              :key="index"
              @click="$router.push('/learn_task')"
            >
              <h6>{{ item.title }}</h6>
              <p>
                {{ item.start_time | formatimes("yyyy-MM-dd") }}
              </p>
            </div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  faculty,
  banner,
  user,
  studyGetList,
  noticeList,
} from "../assets/api.js";
import { getstudentinfos } from "../../../../api/api";
export default {
  name: "homeBanner",
  data() {
    return {
      facultyList: [],
      more: [],
      banner: [],
      infos: {},
      users: {},
      studyList: [],
      noticeLists: [],
    };
  },
  mounted() {},
  methods: {
    ziMounted() {
      this.getuser();
      this.getsubject();
      this.getbanner();
      this.getnoticeList();
      getstudentinfos().then((res) => {
        if (res.code == 0) {
          this.infos = res.data;
          this.getstudyGetList();
        }
      });
    },
    goseacher(item, ite) {
      this.$router.push({
        path: "/search",
        query: {
          second_subject_id: ite.menu_id,
          first_subject_id: item.menu_id,
        },
      });
      if (item.name && !ite.name) {
        let meta = [
          {
            name: item.name,
            show_type: "5",
            id: item.menu_id,
          },
        ];
        window.sessionStorage.setItem("meta", JSON.stringify(meta));
      } else if (item.name && ite.name) {
        let meta = [
          {
            name: item.name,
            show_type: "5",
            id: item.menu_id,
          },
          {
            name: ite.name,
            show_type: "5",
            id: ite.menu_id,
          },
        ];
        window.sessionStorage.setItem("meta", JSON.stringify(meta));
      }
    },
    learns(data) {
      if (data.course_id) {
        this.$router.push({
          path: "/learn",
          query: {
            course_id: data.course_id,
          },
        });
      } else {
        window.location.href = data.link_url;
      }
    },
    notices(id) {
      this.$router.push({
        path: "/notice_detail",
        query: {
          id: id,
        },
      });
    },
    getuser() {
      user().then((res) => {
        if (res.code == 0) {
          this.users = res.data.info;
        }
      });
    },
    getstudyGetList() {
      let params = {
        uid: this.infos.uid,
        limit: 2,
        page: 1,
        status: 0,
      };
      studyGetList(params).then((res) => {
        if (res.code == 0) {
          this.studyList = res.data ? res.data : [];
        }
      });
    },
    getnoticeList() {
      noticeList().then((res) => {
        if (res.code == 0) {
          this.noticeLists = res.data.splice(0, 2);
        }
      });
    },
    getsubject() {
      faculty().then((res) => {
        if (res.code == 0) {
          this.facultyList = res.data.subject_tree.splice(0, 8);
          this.more = res.data.subject_tree;
        }
      });
    },
    getbanner() {
      banner().then((res) => {
        if (res.code == 0) {
          this.banner = res.data.list;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/home";
.homeBanner {
  background: url("../assets/images/home_bg1.png") no-repeat center top #f5f7fa;
  height: 437px;
  padding-top: 20px;
}
.homeWrap .homeWrapdiv1 .homeWrapdiv1fr .homeWrapdiv1frdiv1 {
  background: url("../assets/images/homefrBg.png") center center;
  // background:linear-gradient(to right bottom , #F8F6FB, #F6F7FF);
}
</style>
