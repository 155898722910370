import { get, post } from '@/api/index';
export const liveLessonList = params => get(`/api/course/v1/search/live_lesson_list`, params);//直播
export const courseList = params => get(`/api/course/v1/search/course_list`, params); // 课程
export const faculty = params => get(`/api/infrastruct/v1/faculty`, params); // 科目
export const banner = params => get(`/api/infrastruct/v1/banner`, params); // 轮播
export const user = params => get(`/api/mycourse/v1/user`, params); // 信息
export const studyGetList = params => get(`/api/studytask/v1/study_get_list`, params); // 任务
export const noticeList = params => get(`/api/notice/v1/home_list`, params); //公告






