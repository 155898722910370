<template>
  <div class="homeWrap">
    <homeBanner ref="homeBanner" />
    <!--    <homeLiving />-->
    <div id="homenav">
     <!-- <div class="homexysx" ref="homenav0" v-if="studyhot0show">
        <div class="homeCourse">
          <div class="homeCoursemain main">
            <div class="homeCoursemaind clearfix">
              <div class="hometit fl">
                <img
                  :src="require('./assets/images/home_bg5.png')"
                  alt=""
                />学院上新
              </div>
              <span class="homeCoursemaindmore" @click="getmore(0)"
                >更多<i class="iconfont icon-study_xz"
              /></span>
            </div>
            <div class="homeCoursemaindiv1">
              <ul class="courseWrapparentdivdi1oul clearfix">
                <li
                  v-for="(item, index) in studyhot0"
                  :key="index"
                  :class="(index + 1) % 5 == 0 ? 'on' : ''"
                  @click="learns(item.course_id)"
                >
                  <div>
                    <img :src="item.image" alt="" />
                  </div>
                  <h6>
                    <span>{{ item.typename }}</span
                    >{{ item.title }}
                  </h6>
                  <p>
                    <i class="iconfont icon-icon_yxq"></i>有效期
                    {{ item.start_time | formatimes("yyyy-MM-dd") }}~{{
                      item.end_time | formatimes("yyyy-MM-dd")
                    }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="homexyrm" ref="homenav1" v-if="studyhot1show">
        <div class="homeCourse on">
          <div class="homeCoursemain main">
            <div class="homeCoursemaind clearfix">
              <div class="hometit fl">
                <img
                  :src="require('./assets/images/home_bg6.png')"
                  alt=""
                />学院热门
              </div>
              <span class="homeCoursemaindmore" @click="getmore(1)"
                >更多<i class="iconfont icon-study_xz"
              /></span>
            </div>
            <div class="homeCoursemaindiv1">
              <ul class="courseWrapparentdivdi1oul clearfix">
                <li
                  v-for="(item, index) in studyhot1"
                  :key="index"
                  :class="(index + 1) % 5 == 0 ? 'on' : ''"
                  @click="learns(item.course_id)"
                >
                  <div>
                    <img :src="item.image" alt="" />
                  </div>
                  <h6>
                    <span>{{ item.typename }}</span
                    >{{ item.title }}
                  </h6>
                  <p>
                    <i class="iconfont icon-icon_yxq"></i>有效期
                    {{ item.start_time | formatimes("yyyy-MM-dd") }}~{{
                      item.end_time | formatimes("yyyy-MM-dd")
                    }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>-->
      <div class="homexnsx" ref="homenav2" v-if="studyhot2show">
        <div class="homeCourse">
          <div class="homeCoursemain main">
            <div class="homeCoursemaind clearfix">
              <div class="hometit fl">
                <img
                  :src="require('./assets/images/home_bg7.png')"
                  alt=""
                />校内上新
              </div>
              <span class="homeCoursemaindmore" @click="getmore(2)"
                >更多<i class="iconfont icon-study_xz"
              /></span>
            </div>
            <div class="homeCoursemaindiv1">
              <ul class="courseWrapparentdivdi1oul clearfix">
                <li
                  v-for="(item, index) in studyhot2"
                  :key="index"
                  :class="(index + 1) % 5 == 0 ? 'on' : ''"
                  @click="learns(item.course_id)"
                >
                  <div>
                    <img v-if="item.image" :src="item.image" alt="" />
                    <img v-else :src="require('../../../assets/images/nocourse.png')" />
                  </div>
                  <h6>
                    <span>{{ item.typename }}</span
                    >{{ item.title }}
                  </h6>
                  <p v-if="item.start_time=='-28800'">
                    <i class="iconfont icon-icon_yxq"></i>长期有效
                  </p>
                  <p v-else>
                    <i class="iconfont icon-icon_yxq"></i>有效期
                    {{ item.start_time | formatimes("yyyy-MM-dd") }}~{{
                      item.end_time | formatimes("yyyy-MM-dd")
                    }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="homexnrm" ref="homenav3" v-if="studyhot3show">
        <div class="homeCourse on">
          <div class="homeCoursemain main">
            <div class="homeCoursemaind clearfix">
              <div class="hometit fl">
                <img
                  :src="require('./assets/images/home_bg8.png')"
                  alt=""
                />校内热门
              </div>
              <span class="homeCoursemaindmore" @click="getmore(3)"
                >更多<i class="iconfont icon-study_xz"
              /></span>
            </div>
            <div class="homeCoursemaindiv1">
              <ul class="courseWrapparentdivdi1oul clearfix">
                <li
                  v-for="(item, index) in studyhot3"
                  :key="index"
                  :class="(index + 1) % 5 == 0 ? 'on' : ''"
                  @click="learns(item.course_id)"
                >
                  <div>
                    <img v-if="item.image" :src="item.image" alt="" />
                    <img v-else :src="require('../../../assets/images/nocourse.png')" />
                  </div>
                  <h6>
                    <span>{{ item.typename }}</span
                    >{{ item.title }}
                  </h6>
                  <p v-if="item.start_time=='-28800'">
                    <i class="iconfont icon-icon_yxq"></i>长期有效
                  </p>
                  <p v-else>
                    <i class="iconfont icon-icon_yxq"></i>有效期
                    {{ item.start_time | formatimes("yyyy-MM-dd") }}~{{
                      item.end_time | formatimes("yyyy-MM-dd")
                    }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <ul class="homeWrapleft" ref="homeWrapleft">-->
<!--      <li-->
<!--        @click="handleToggle(0)"-->
<!--        :class="activeIndex == 0 ? 'on' : ''"-->
<!--        v-if="studyhot0show"-->
<!--      >-->
<!--        学院上新-->
<!--      </li>-->
<!--      <li-->
<!--        @click="handleToggle(1)"-->
<!--        :class="activeIndex == 1 ? 'on' : ''"-->
<!--        v-if="studyhot1show"-->
<!--      >-->
<!--        学院热门-->
<!--      </li>-->
<!--      <li-->
<!--        @click="handleToggle(2)"-->
<!--        :class="activeIndex == 2 ? 'on' : ''"-->
<!--        v-if="studyhot2show"-->
<!--      >-->
<!--        校内上新-->
<!--      </li>-->
<!--      <li-->
<!--        @click="handleToggle(3)"-->
<!--        :class="activeIndex == 3 ? 'on' : ''"-->
<!--        v-if="studyhot3show"-->
<!--      >-->
<!--        校内热门-->
<!--      </li>-->
<!--    </ul>-->
  </div>
</template>

<script>
import { checklogin } from "../../../api/api";

import homeBanner from "./components/homeBanner";
import { courseList } from "./assets/api";
import { setCookie, getCookie } from "../../../assets/js/cookie";
export default {
  components: { homeBanner },
  data() {
    return {
      activeIndex: -1,
      faculty_id: "",
      studyhot0: [],
      studyhot1: [],
      studyhot2: [],
      studyhot3: [],
      studyhot0show: true,
      studyhot1show: true,
      studyhot2show: true,
      studyhot3show: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleTabFix);

    this.$refs.homeBanner.ziMounted();
    this.faculty_id = getCookie("faculty_id");
    this.getList("", 10, 2, "new");
    this.getList("", 20, 3, "hot");
  },
  methods: {
    getmore(val) {
      let obj = JSON.parse(window.sessionStorage.getItem("infos"));
      let meta = [
        {
          name: obj.faculty_name,
          show_type: "2",
          id: obj.faculty_id,
        },
      ];
      if (val == 0) {
        window.sessionStorage.setItem("meta", JSON.stringify(meta));
        this.$router.push({
          path: "/search",
          query: { faculty_id: this.faculty_id, sort: "new" },
        });
      }
      if (val == 1) {
        window.sessionStorage.setItem("meta", JSON.stringify(meta));
        this.$router.push({
          path: "/search",
          query: { faculty_id: this.faculty_id, sort: "hot" },
        });
      }
      if (val == 2) {
        window.sessionStorage.removeItem("meta");
        this.$router.push({ path: "/search", query: { sort: "new" } });
      }
      if (val == 3) {
        window.sessionStorage.removeItem("meta");
        this.$router.push({ path: "/search", query: { sort: "hot" } });
      }
    },
    learns(id) {
      this.$router.push({
        path: "/learn",
        query: {
          course_id: id,
          metaType: 1,
        },
      });
    },
    getList(id, limit, type, sort) {
      let params = {
        faculty_id: id,
        sort: sort,
        limit: limit,
        teacher_only: 0,
      };
      courseList(params).then((res) => {
        if (res.code == 0) {
          let list = JSON.parse(JSON.stringify(res.data.row));
          // if (type == 0) {
          //   this.studyhot0 = list.slice(0, 10);
          //   if (this.studyhot0.length == 0) {
          //     this.studyhot0show = false;
          //   }
          // } else if (type == 1) {
          //   let studyhot1 = list.slice(0, 20);
          //   this.studyhot1 = this.clear(studyhot1, this.studyhot0).slice(0, 10);
          //   if (this.studyhot1.length == 0) {
          //     this.studyhot1show = false;
          //   }
          // } else if (type == 2) {
          //   let studyhot2 = list.slice(0, 30);
          //   let arr1 = this.studyhot0.concat(this.studyhot1);
          //   this.studyhot2 = this.clear(studyhot2, arr1).slice(0, 10);
          //   if (this.studyhot2.length == 0) {
          //     this.studyhot2show = false;
          //   }
          // } else if (type == 3) {
          //   let studyhot3 = list.slice(0, 40);
          //   let arr1 = this.studyhot0.concat(this.studyhot1, this.studyhot2);
          //   this.studyhot3 = this.clear(studyhot3, arr1).slice(0, 10);
          //   if (this.studyhot3.length == 0) {
          //     this.studyhot3show = false;
          //   }
          // }
          if (type == 2) {
            this.studyhot2 = list.slice(0, 10);
            if (this.studyhot2.length == 0) {
              this.studyhot2show = false;
            }
          } else if (type == 3) {
            let studyhot3 = list.slice(0, 20);
            this.studyhot3 = this.clear(studyhot3, this.studyhot2).slice(0, 10);
            if (this.studyhot3.length == 0) {
              this.studyhot3show = false;
            }
          }
        }
      });
    },
    clear(arr2, arr1) {
      arr1.map((item) => {
        arr2.map((it, index) => {
          if (item.course_id == it.course_id) {
            arr2.splice(index, 1);
          }
        });
      });
      return arr2;
    },
    handleTabFix() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      for (
        let index = 0;
        index < document.querySelectorAll("#homenav>div").length;
        index++
      ) {
        if (
          scrollTop <
          document.querySelectorAll("#homenav>div")[0].offsetTop - 73
        ) {
          this.activeIndex = -1;
        } else {
          if (
            scrollTop >
            document.querySelectorAll("#homenav>div")[index].offsetTop - 73
          ) {
            this.activeIndex = index;
          }
        }
      }
    },
    handleToggle(index) {
      this.activeIndex = index;
      window.scrollTo({
        top: this.$refs["homenav" + index].offsetTop - 72,
        behavior: "smooth",
      });
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleTabFix);
  },
};
</script>

<style scoped lang="scss">
@import "./assets/home";
</style>
